import React, { useEffect, useRef, useState } from 'react';
import "./static_html/assets/css/custom.css";
import "./static_html/assets/css/fontawesome.css";
import "./static_html/assets/css/style.css";

import logo_full_white from './static_html/images/logos/logo-full-white-nobg.png';
import logo_full_white_cropped from "./static_html/images/logos/logo-full-white-nobg-cropped.png";
import intro_illustration from "./static_html/images/arts/intro-section-illustration.png";

import speakeasy_project from "./static_html/images/projects/speakeasy.png";
import restai_project from "./static_html/images/projects/restai.png";

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'glightbox/dist/js/glightbox.min.js';
import 'glightbox/dist/css/glightbox.min.css';

const Home = () => {
  const sectionRefs = useRef([]);
  const [currentSection, setCurrentSection] = useState('home');

  useEffect(() => {
    // Function to update the navbar links based on scroll position
    const updateNavBar = () => {
      sectionRefs.current.forEach(section => {
        if (window.scrollY >= section.offsetTop - 200) {
          setCurrentSection(section.id);
        }
      });
    };

    // Add scroll event listener
    window.addEventListener('scroll', updateNavBar);

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      window.removeEventListener('scroll', updateNavBar);
    };
  }, []);

  useEffect(() => {
    // Set initial section when the component loads
    setCurrentSection('home');
  }, []);

  return (
    <div>
      {/* ////////////////////////////////////////////////////////////////////////////////////////
                                     START SECTION 1 - THE NAVBAR SECTION  
      //////////////////////////////////////////////////////////////////////////////////////////// */}
      <nav className="navbar navbar-expand-lg navbar-dark menu shadow fixed-top">
        <div className="container">
          <a className="navbar-brand" href="#home">
            <picture>
              <source
                media="(min-width: 770px)"
                srcSet={logo_full_white}
              />
              <img
                src={logo_full_white_cropped}
                height="54px"
                alt="logo"
              />
            </picture>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className={`nav-link ${currentSection === 'home' ? 'active' : ''}`} href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${currentSection === 'services' ? 'active' : ''}`} href="#services">
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${currentSection === 'faq' ? 'active' : ''}`} href="#faq">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${currentSection === 'contact' ? 'active' : ''}`} href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////
                                    START SECTION 2 - THE INTRO SECTION  
        ///////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <section id="home" className="intro-section" ref={el => sectionRefs.current[0] = el}>
        <div className="container">
          <div className="row align-items-center text-white">
            <div className="col-md-6 intros text-start">
              <h1 className="display-2">
                <span className="display-2--intro">
                  Welcome to the Digital Kloud
                </span>
                <span className="display-2--description lh-base mb-4 mt-4">
                  where we use {" "}
                  <span style={{ borderBottom: "1px solid #FFF" }}>small</span> {" "}
                  software to make <span style={{ borderBottom: "1px solid #FFF" }}>big</span> changes.
                </span>
              </h1>
              <button
                type="button"
                onClick={() => document.getElementById("contact").scrollIntoView()}
                className="rounded-pill btn-rounded"
              >
                Get in Touch <span><i className="fas fa-arrow-right"></i></span>
              </button>
            </div>
            <div className="col-md-6 intros text-end">
              <div className="video-box">
                <picture>
                  <source
                    srcSet={intro_illustration}
                    media="(min-width: 770px)"
                  />
                  <img className="img-fluid" alt="Intro Illustration" />
                </picture>
              </div>
            </div>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 300">
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,160L48,176C96,192,192,224,288,208C384,192,480,128,576,133.3C672,139,768,213,864,202.7C960,192,1056,96,1152,74.7C1248,53,1344,107,1392,133.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </section>

      {/* //////////////////////////////////////////////////////////////////////////////////////////////
                                START SECTION 4 - THE SERVICES  
        /////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <span id="services" style={{ position: "absolute", transform: "translateY(-6rem)" }} className="section" ref={el => sectionRefs.current[1] = el}></span>
      <section className="services">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold">Our Projects</h1>
            <div className="heading-line mb-3"></div>
          </div>
          {/* START THE DESCRIPTION CONTENT */}
          {/* <div className="row pt-2 pb-2 mt-0 mb-3">
            <div className="col-md-6 border-right">
              <div className="bg-white p-3">
                <h2 className="fw-bold text-capitalize text-center">
                  Our Services Range From Initial Design To Deployment, Anywhere, Anytime.
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white p-4 text-start" style={{ position: "relative", top: "-1rem" }}>
                <p className="fw-light">
                  Digital Kloud is your comprehensive digital marketing partner. Through precise, innovative,
                  and tailored solutions to enhance your online presence and drive business growth, we
                  specialize in elevating your brand's visibility and impact in the digital landscape.
                </p>
              </div>
            </div>
          </div> */}
          <p className="fw-light" style={{textWrap: "balance", textAlign: "center" }} >
            At Digital Kloud, we passionately work on innovative projects designed to make a real impact. 
            Every project is crafted with purpose, creativity, and precision to empower businesses and individuals alike, driving remarkable success and shaping a brighter, more connected future.
          </p>
        </div>

        {/* START THE CONTENT FOR THE SERVICES */}
        <div className="container">
          {/* START THE MARKETING CONTENT */}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content border-right" style={{textAlign: "right"}}>
                <h3 className="display-3--title">SpeakEasy</h3>
                <p className="lh-lg">
                  SpeakEasy is an innovative AI-powered platform designed to revolutionize the traditional interview processes. 
                  By simulating realistic industry-technical interview scenarios, SpeakEasy offers both companies and individuals a dynamic space to practice, refine, and excel at communication.
                  SpeakEasy's real-time simulations and comprehensive performance analytics are designed to unlock your full potential—transforming every practice session into a stepping stone toward success.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
              <div className="services__pic">
                <picture>
                  <source media="(min-width: 770px)" srcSet={speakeasy_project} alt="SpeakEasy logo" />
                  <img className="img-fluid project-image" alt="SpeakEasy logo" />
                </picture>
              </div>
            </div>
          </div>

          <div style={{ height: '5em' }}></div>

          {/* START THE ADVERTISING CONTENT */}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
              <div className="services__pic">
                <picture>
                  <source media="(min-width: 770px)" srcSet={restai_project} alt="RestAI logo" />
                  <img className="img-fluid project-image" alt="RestAI logo" />
                </picture>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content border-left">
                <h3 className="display-3--title mt-1">RestAI</h3>
                <p className="lh-lg">
                  RestAI is an innovative, AI-powered tool that transforms your favorite audio tracks into accurate sheet music and dynamic MIDI visualizers.
                  Designed for music lovers of all skill levels, RestAI eliminates the need for advanced transcription-by-ear by providing an effortless way to learn and enjoy songs.
                  Whether you’re a budding musician eager to pick up a new tune or a seasoned performer looking to explore fresh arrangements, RestAI’s state-of-the-art transcription technology captures every note and chord with remarkable precision.
                </p>
              </div>
            </div>
          </div>

          <div style={{ height: '5em' }}></div>
        </div>
      </section>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////
                       START SECTION 6 - THE FAQ 
      //////////////////////////////////////////////////////////////////////////////////////////////////////*/}
      <span id="faq" style={{ position: 'absolute', transform: 'translateY(-7.5rem)' }} className="section" ref={el => sectionRefs.current[2] = el}></span>
      {/* <section id="faq" class="faq"> */}
      <section className="faq">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold text-uppercase">faq</h1>
            <div className="heading-line mb-3"></div>
            <p className="lead">Get to Know Us Better - Frequently Asked Question</p>
          </div>
          {/* ACCORDION CONTENT  */}
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="accordion" id="accordionExample">
                {/* ACCORDION ITEM 1 */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      How can Digital Kloud help my business grow?
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>Come and talk to us and we can find out! </strong>
                      At Digital Kloud, we blend our diverse expertise in software development, all the way from frontend web design to backend database management, to help your business thrive.
                      Whether you’re looking to create a sleek new platform, optimize workflows, or develop targeted marketing campaigns, our team is ready to deliver tailored solutions that meet your goals.
                      <br /><br />
                      During your complimentary consultation, we’ll dive into your objectives and explore how our services can give you an edge in today’s competitive landscape.
                      And with our spirited, collaborative approach, we’ll ensure the process is as enjoyable as it is effective. Let us show you how Digital Kloud can transform your digital presence, boost productivity, and help your company reach new heights!
                    </div>
                  </div>
                </div>
                {/* ACCORDION ITEM 2 */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What other projects is Digital Kloud planning on working on?
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Our project roadmap is as dynamic as our team—<strong>it’s driven by the ideas and needs of our community. </strong>
                      We welcome your suggestions, and you can share your thoughts using the contact form below.
                      Whether you simply have an idea or are interested in joining our team to steer our creative direction, we’re excited to collaborate.
                      Together, we can build software that makes a bigger impact on the world.
                    </div>
                  </div>
                </div>
                {/* ACCORDION ITEM 3 */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Are your services suitable for both startups and large enterprises?
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>Yes indeed! </strong>
                      Digital Kloud prides itself on offering flexible, scalable solutions that cater to businesses of all sizes. 
                      Whether you’re a startup looking to make a big splash or an established enterprise in need of sophisticated digital strategies, our customized approach adapts to your unique challenges. 
                      Connect with us, and we’ll craft the perfect solution to drive your success.
                    </div>
                  </div>
                </div>
                {/* ACCORDION ITEM 4 */}
                <div className="accordion-item shadow mb-3">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Is Digital Kloud looking to hire?
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>Absolutely! </strong>
                      At Digital Kloud, we believe that expanding our talented team is key to amplifying our impact on the world.
                      We’re always on the lookout for passionate, creative minds eager to join our journey.
                      If you’re driven to innovate and help us shape the future of digital solutions, please reach out at <a href="mailto:info@digital-kloud.com?subject=Looking%20to%20join%20the%20Digital%20Kloud%21">info@digital-kloud.com</a> (or through the form below).
                      We’d love to explore opportunities with you!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////
                START SECTION 8 - GET STARTED  
      ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* <div id="contact-tag" style="margin-top:5rem;"></div> */}
      <span id="contact" style={{ position: "absolute", transform: "translateY(2rem)" }} className="section" ref={el => sectionRefs.current[3] = el}></span>
      {/* <section id="contact" class="get-started"> */}
      <section className="get-started">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold text-capitalize">Contact Us</h1>
            <div className="heading-line mb-2"></div>
            <p className="lh-lg">
              Set up your first free consultation meeting with us!
            </p>
          </div>

          {/* START THE CTA CONTENT  */}
          <div className="row text-white">
            <div className="col-12 col-lg-6 gradient shadow p-3">
              <div className="cta-info w-100">
                <h4 className="display-4 fw-bold">Get In Touch Today!</h4>
                <p className="lh-lg">
                  Connect with us today to schedule a complimentary consultation with one of our experienced agents. 
                  At Digital Kloud, we're dedicated to understanding your unique business objectives and providing tailored solutions to drive your success. 
                  Let's explore how our expertise can elevate your online presence and take advantage of software to propel your business forward.
                </p>
                <br />
                <h3 className="display-3--brief">3 Easy Steps</h3>
                <ul className="cta-info__list">
                  <li>Connect with us.</li>
                  <li>Choose what services best complement you.</li>
                  <li>Get to work!</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6 bg-white shadow p-3">
              <div className="form w-100 pb-2">
                <h4 className="display-3--title mb-3">Start Your Journey With Us!</h4>
                <form action="https://formsubmit.co/info@digital-kloud.com" method="POST" className="row">
                  <div className="col-lg-6 col-md mb-3">
                    <input
                      name="First Name"
                      type="text"
                      placeholder="First Name"
                      id="inputFirstName"
                      className="shadow form-control form-control-lg"
                    />
                  </div>
                  <div className="col-lg-6 col-md mb-3">
                    <input
                      name="Last Name"
                      type="text"
                      placeholder="Last Name"
                      id="inputLastName"
                      className="shadow form-control form-control-lg"
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <input
                      name="Email"
                      type="email"
                      placeholder="Email Address"
                      id="inputEmail"
                      className="shadow form-control form-control-lg"
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <textarea
                      name="Message"
                      placeholder="Tell us about you!"
                      id="inputMessage"
                      rows="8"
                      className="shadow form-control form-control-lg"
                    ></textarea>
                  </div>
                  <div className="text-center d-grid mt-1 mb-5">
                    <button type="submit" className="btn btn-primary rounded-pill pt-3 pb-3">
                      Submit
                      <i className="fas fa-paper-plane" style={{paddingLeft: "0.5rem"}}></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////////////////////////////////////////////////////////////////
                         START SECTION 9 - THE FOOTER  
      //////////////////////////////////////////////////////////////////////////////////////////// */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            {/* CONTENT FOR THE MOBILE NUMBER */}
            <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
              <div className="contact-box__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-phone-call"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ position: "relative", top: "-0.3rem" }}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                  <path d="M15 7a2 2 0 0 1 2 2" />
                  <path d="M15 3a6 6 0 0 1 6 6" />
                </svg>
              </div>
              <div className="contact-box__info">
                <a href="tel:+14088295761" className="contact-box__info--title">+1 (408) 829 - 5761</a>
                <p className="contact-box__info--subtitle">Sun-Sat 9am-6pm</p>
              </div>
            </div>
            {/* CONTENT FOR EMAIL */}
            <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
              <div className="contact-box__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-mail-opened"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ position: "relative", top: "-0.4rem" }}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="3 9 12 15 21 9 12 3 3 9" />
                  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />
                  <line x1="3" y1="19" x2="9" y2="13" />
                  <line x1="15" y1="13" x2="21" y2="19" />
                </svg>
              </div>
              <div className="contact-box__info">
                <a href="mailto:info@digital-kloud.com" className="contact-box__info--title">info@digital-kloud.com</a>
                <p className="contact-box__info--subtitle">Online support</p>
              </div>
            </div>
            {/* CONTENT FOR LOCATION */}
            <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
              <div className="contact-box__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-map-2"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ position: "relative", top: "-0.2rem" }}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="18" y1="6" x2="18" y2="6.01" />
                  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                  <line x1="9" y1="4" x2="9" y2="17" />
                  <line x1="15" y1="15" x2="15" y2="20" />
                </svg>
              </div>
              <div className="contact-box__info">
                <p className="contact-box__info--title" style={{marginBottom:"0rem"}}>Toronto, ON</p>
                <p className="contact-box__info--subtitle">Canada</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* START THE COPYRIGHT INFO */}
      <div className="footer-bottom pt-5 pb-5">
        <div className="container">
        <div className="row text-center text-white">
            <div className="col-12">
            <div className="footer-bottom__copyright">
                &copy; Copyright 2024{" "}
                <a href="#home">Digital Kloud</a> | Founded by{" "}
                <a href="http://linkedin.com/in/kalp-shah-2003/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Kalp Shah
                </a>
                <br /><br />
            </div>
            </div>
        </div>
        </div>
      </div>

      {/* BACK TO TOP BUTTON */}
        <a href="#home" className="shadow btn-primary rounded-circle back-to-top">
        <i className="fas fa-chevron-up"></i>
      </a>
    </div>
  );
};

export default Home;
